import {IconProps} from "@sirdata/ui-lib";
import {TaxonomyPathItem} from "./TaxonomyNode";

export enum TaxonomyTopTier {
    APPAREL = "Apparel",
    ARTS_AND_ENTERTAINMENT = "Arts & Entertainment",
    AUTO_AND_VEHICLES = "Auto & Vehicles",
    BANK_AND_INSURANCE = "Bank & Insurance",
    BEAUTY_AND_PERSONAL_CARE = "Beauty & Personal Care",
    BRAND = "Brand",
    BRAND_SAFETY = "Brand Safety",
    BUSINESS_AND_INDUSTRY = "Business & Industry",
    CHILDCARE = "Childcare",
    DEMOGRAPHICS = "Demographics",
    ECOLOGY_AND_ENVIRONMENT = "Ecology & Environment",
    FMCG_FOOD_AND_DRINK = "FMCG I Food & Drink",
    HABITS_AND_LIFESTYLE = "Habits & Lifestyle",
    HIGH_TECH = "High Tech",
    HOME_AND_GARDEN = "Home & Garden",
    LANGUAGES = "Languages",
    LIFE_EVENTS = "Life Events",
    LOCATION = "Location",
    MEDICAL_HEALTH = "Medical Health",
    NEWS = "News",
    PETS_AND_ANIMALS = "Pets & Animals",
    PRIVACY_SAFETY = "Privacy Safety",
    SPORTS = "Sports",
    TRAVEL = "Travel",
    VIDEO_GAMING = "Video Gaming",
    WELLBEING = "Wellbeing"
}

export type TaxonomyTopTierItem = {
    name: TaxonomyTopTier;
    link: string;
    icon: IconProps;
}

export const TAXONOMY_TOP_TIERS: TaxonomyTopTierItem[] = [
    {name: TaxonomyTopTier.APPAREL, link: "apparel", icon: {name: "checkroom"}},
    {name: TaxonomyTopTier.ARTS_AND_ENTERTAINMENT, link: "arts-entertainment", icon: {name: "theater_comedy"}},
    {name: TaxonomyTopTier.AUTO_AND_VEHICLES, link: "auto-vehicles", icon: {name: "directions_car"}},
    {name: TaxonomyTopTier.BANK_AND_INSURANCE, link: "bank-insurance", icon: {name: "account_balance"}},
    {name: TaxonomyTopTier.BEAUTY_AND_PERSONAL_CARE, link: "beauty-personal-care", icon: {name: "health_and_beauty"}},
    {name: TaxonomyTopTier.BRAND, link: "brand", icon: {name: "sell"}},
    {name: TaxonomyTopTier.BRAND_SAFETY, link: "brand-safety", icon: {name: "security"}},
    {name: TaxonomyTopTier.BUSINESS_AND_INDUSTRY, link: "business-industry", icon: {name: "factory"}},
    {name: TaxonomyTopTier.CHILDCARE, link: "childcare", icon: {name: "child_friendly"}},
    {name: TaxonomyTopTier.DEMOGRAPHICS, link: "demographics", icon: {name: "groups"}},
    {name: TaxonomyTopTier.ECOLOGY_AND_ENVIRONMENT, link: "ecology-environment", icon: {name: "eco"}},
    {name: TaxonomyTopTier.FMCG_FOOD_AND_DRINK, link: "fmcg-i-food-drink", icon: {name: "fastfood"}},
    {name: TaxonomyTopTier.HABITS_AND_LIFESTYLE, link: "habits-lifestyle", icon: {name: "nordic_walking"}},
    {name: TaxonomyTopTier.HIGH_TECH, link: "high-tech", icon: {name: "computer", outlined: true}},
    {name: TaxonomyTopTier.HOME_AND_GARDEN, link: "home-garden", icon: {name: "cottage"}},
    {name: TaxonomyTopTier.LANGUAGES, link: "languages", icon: {name: "language"}},
    {name: TaxonomyTopTier.LIFE_EVENTS, link: "life-events", icon: {name: "family_restroom"}},
    {name: TaxonomyTopTier.LOCATION, link: "location", icon: {name: "location_on"}},
    {name: TaxonomyTopTier.MEDICAL_HEALTH, link: "medical-health", icon: {name: "medical_services"}},
    {name: TaxonomyTopTier.NEWS, link: "news", icon: {name: "newspaper"}},
    {name: TaxonomyTopTier.PETS_AND_ANIMALS, link: "pets-animals", icon: {name: "pets"}},
    {name: TaxonomyTopTier.PRIVACY_SAFETY, link: "privacy-safety", icon: {name: "security"}},
    {name: TaxonomyTopTier.SPORTS, link: "sports", icon: {name: "fitness_center"}},
    {name: TaxonomyTopTier.TRAVEL, link: "travel", icon: {name: "flight"}},
    {name: TaxonomyTopTier.VIDEO_GAMING, link: "video-gaming", icon: {name: "videogame_asset"}},
    {name: TaxonomyTopTier.WELLBEING, link: "wellbeing", icon: {name: "self_improvement"}}
];

export function getTaxonomyTopTierIcon(pathItem?: TaxonomyPathItem): IconProps {
    return TAXONOMY_TOP_TIERS.find(({link}) => link === pathItem?.link)?.icon || {name: "folder"};
}
